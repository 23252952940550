.Enterprise {
  margin-top: 60.5px;
  max-width: $bp-largest;
  margin: 0 auto;

  .Enterprise_Special {
    color: #8643c0;
  }

  .error {
    color: #ef4d4d;
    margin-top: 8px !important;
    font-size: 14px;
    font-weight: bold;
    margin-left: 4px;

    &.form {
      margin-bottom: 24px;
      text-align: center;
    }
  }

  .Enterprise_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 104px;

    @media only screen and (max-width: $bp-large) {
      padding: 0 24px;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      img {
        max-width: 236.41px;
      }
    }

    @media screen and (max-width: 875px) {
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      margin-top: 0px;

      img {
        max-width: 236.41px;
      }
    }
  }

  .Enterprise_OverviewTitle {
    max-width: 712px;
    margin-bottom: 60.5px;
    margin-top: 60.5px;
    @media screen and (max-width: 480px) {
      margin-top: 40px;
      margin-bottom: 0px;
      // margin-top: 8px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
    }

    .Enterprise_Paragraph {
      margin-top: 24px;
      margin-bottom: 32px;
      color: #1b3155;
      font-weight: 700;
      font-size: 18px;
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }

    .Enterprise_Title {
      color: #1b3155;
      font-weight: 900;
      font-size: 44px;
      @media screen and (max-width: 480px) {
        text-align: start !important;
      }
      @media screen and (max-width: 875px) {
        font-size: 24px;
      }
    }
  }

  .Enterprise_Information_Overview {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 104px;
    margin-top: 128px;
    margin-bottom: 64px;

    @media screen and (max-width: 480px) {
      margin-top: 64px;
      margin-bottom: 32px;
    }

    @media only screen and (max-width: $bp-large) {
      padding: 0 24px;
    }

    .carousel .slide {
      display: flex;
      justify-content: center;
    }

    .Enterprise_Information {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 24px;
      column-gap: 32px;
      margin-top: 32px;
      max-width: 1232px;
      @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 16px;
        margin-top: 0px;
      }
    }
    .Enterprise_information_children {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 32px;
      background: blue;
      background: linear-gradient(172.11deg, #ede9fe 5.72%, #ffffff 93.55%);
      box-shadow: 0px 12px 12px rgba(27, 49, 85, 0.08),
        0px 2px 8px rgba(27, 49, 85, 0.2);
      min-height: 292px;
      border-radius: 8px;
      height: 100%;

      @media screen and (max-width: 875px) {
        min-height: 328px;
      }

      .title {
        color: #071b3c;
        font-weight: 900;
        font-size: 20px;
        margin-top: 24px;
      }

      .paragraph {
        color: #071b3c;
        font-size: 16px;
        font-weight: 400;
        margin-top: 16px;
      }

      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background: linear-gradient(91.72deg, #d506d6 0.15%, #6b57b8 98.03%);

        @media screen and (max-width: 875px) {
          margin-top: 4px;
          img {
            width: 27px;
            height: 27px;
          }
        }
      }
    }
  }

  .Enterprise_Information_Testimonials {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/testimonials.svg");
    background-repeat: no-repeat;
    height: 750px;
    background-size: cover;
    margin-top: 78px;
    overflow: hidden;
    .carousel .slide {
      display: flex;
      justify-content: center;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      left: 0;
    }
    @media screen and (max-width: 875px) {
      background-size: 100%;
      height: 350px;
      margin-top: -25px;
    }
    @media screen and (max-width: 730px) {
      background-size: 750px 100%;
      height: 305px;

      .carousel {
        margin-bottom: -20px;
      }
    }

    .Enterprise_Testimonials {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 24px;
      column-gap: 16px;
      max-width: 610px;
      font-weight: 400;
      font-size: 14px;
      color: #071b3c;
    }

    .Testimonial {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (max-width: 875px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 32px;

        .Testimonial_Tooltip {
          padding: 19px 16px;
          box-shadow: 0px 12px 12px rgba(27, 49, 85, 0.08),
            0px 2px 8px rgba(27, 49, 85, 0.2);
          max-width: 202px;
          &::before,
          &::after {
            display: none;
          }

          .Testimonial_ContainerName {
            align-items: flex-end;
            .Testimonial_Title {
              color: #8d98aa;
              font-size: 12px;
            }

            .Testimonial_Name {
              font-size: 14px;
            }
          }
        }

        .Testimonial_PersonContainer {
          display: none;
        }
      }
    }

    .Testimonial_PersonContainer {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      margin-left: 15px;
    }

    .Testimonial_ContainerName {
      display: flex;
      flex-direction: column;
      margin-left: 24px;

      @media screen and (max-width: 875px) {
        margin-top: 16px;
      }

      .Testimonial_Name {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #071b3c;
      }

      .Testimonial_Title {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        margin-top: 4px;
      }
    }

    .Testimonial_Avatar {
      width: 48px;
      height: 48px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      background: #d7dfe7;
    }

    .Testimonial_Tooltip {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 12px 12px rgba(27, 49, 85, 0.08),
        0px 2px 8px rgba(27, 49, 85, 0.2);
      padding: 16px 30px;
      border-radius: 8px;
      width: 100%;
      max-width: 296px;

      &:before {
        position: absolute;
        bottom: -10px;
        left: 30px;
        content: "";
        background: white;
        height: 20px;
        width: 20px;
        transform: rotate(45deg);
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        z-index: -1;

        box-shadow: 0px 12px 12px rgba(27, 49, 85, 0.08),
          0px 2px 8px rgba(27, 49, 85, 0.2);
      }

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 10px;
        margin-left: 15px;
        border-width: 15px;
        border-style: solid;
        border-color: white transparent transparent transparent;
        z-index: 1;
      }
    }
  }

  .Testimonial_Partnership {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 953.69px;
    text-align: center;
    padding: 0 50px;
    margin-top: 32px;

    @media screen and (max-width: 875px) {
      padding: 0 24px;
    }

    .Testimonial_PartnershipButton {
      margin-top: 34px;
      margin-bottom: 36px;

      @media screen and (max-width: 875px) {
        margin-top: 18px;
        margin-bottom: 27px;
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none;

        .Testimonial_All {
          flex: 1;
          color: #6b57b8;
          font-weight: 700;
          font-size: 14px;
        }

        img {
          width: 16px;
        }
      }
    }

    .Testimonial_TitlePartner {
      margin-bottom: 33px;
      font-weight: 900;
      color: #1b3155;
      font-size: 28px;

      @media screen and (max-width: 875px) {
        margin-bottom: 16px;
      }
    }

    img {
      width: 100%;
    }
  }

  .Testimonial_FormContainer {
    margin: 0 24px;
  }
  .Testimonial_Form {
    position: relative;
    padding: 32px 23px;
    background: #1b3155;
    border-radius: 8px;
    max-width: 608px;
    margin: 0 auto;
    z-index: 15;

    @media screen and (max-width: 875px) {
      padding: 16px 39px;
    }

    .Testimonial_Send {
      display: flex;
      justify-content: flex-end;
    }

    .Testimonial_FormTitle {
      font-weight: 700;
      font-size: 28px;
      color: #fff;
      text-align: center;
      margin-bottom: 30px;

      @media screen and (max-width: 875px) {
        font-weight: 900;
        margin-bottom: 24px;
      }
    }

    input,
    textarea {
      border: 1px solid #8d98aa;
      border-radius: 48px;
      padding: 10px 16px;
      color: #1b3155;
      outline-width: 0;
      font-size: 16px;
      width: 100%;

      &::placeholder {
        color: #8d98aa;
      }
    }

    .input {
      &.bottom {
        margin-bottom: 32px;
      }
    }

    textarea {
      min-height: 165px;
      padding: 12px 16px;
      border-radius: 16px;
      font-family: "Nunito Sans", sans-serif;
      margin-top: 40px;
      resize: none;
    }
  }

  .Testimonial_FormBackground {
    position: relative;
    margin-top: -300px;
    background-image: url("/../src/assets/SubmitBackground.svg");
    height: 350px;
    width: 100%;
    background-size: cover;
    z-index: 5;

    @media screen and (max-width: 875px) {
      margin-top: -325px;
    }
  }
}

.FormSubmitOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #041027;
  opacity: 0.7;
  z-index: 30;
}

.FormModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 10;
  height: 100%;
  width: 100%;
  max-width: 640px;
  max-height: 350px;
  box-shadow: 0px 0px 3px rgba(27, 49, 85, 0.16),
    0px 4px 8px rgba(27, 49, 85, 0.24);
  border-radius: 8px;
  z-index: 30;

  .FormModal_TextOverview {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .FormModal_Text {
    color: #1b3155;
    margin-top: 25px;
    text-align: center;

    .FormModalTitle {
      text-transform: uppercase;
      font-size: 24px;
    }

    .FormModalParagraph {
      font-size: 18px;
      margin-top: 32px;
    }
  }

  .FormModal_Success {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #06b5dd;
    padding: 26px;
    width: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    img {
      width: 64px;
      height: 64px;
    }
  }
}

.Enterprise_get_in_touch {
  margin-left: 0px;
  width: 139px;
  height: 44px;
  background: #6b57b8;
  border-radius: 24px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #fff;

  &.okay-button {
    width: 66px;
    height: 36px;
    margin-top: 58px;
    margin-bottom: 24px;
  }

  // @media screen and (max-width: 875px) {
  //   width: 92px;
  //   height: 24px;
  //   font-size: 12px;
  // }

  // @media screen and (max-width: 875px) {
  //   margin: 0 auto;
  // }

  // @media screen and (max-width: 1032px) {
  //   margin-top: 12px;
  // }

  &.form-btn {
    width: 74px;
    height: 42px;
    background: #06b5dd;
    border-radius: 24px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    margin-top: 32px;
    margin-bottom: 8px;

    @media screen and (max-width: 875px) {
      margin: 0;
      margin-top: 16px;
    }
  }
}

.featured-on {
  background: white;
  text-align: center;

  &.enterprise {
    padding-top: 40px;
  }

  @media screen and (max-width: 600px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  // @media screen and (max-width: 875px) {
  //   &.enterprise {
  //     margin-top: 0px !important;
  //     padding-top: 0px !important;
  //     margin-bottom: 48px !important;
  //   }

  //   &.info {
  //     margin-top: 18px;
  //   }
  // }

  &.enterprise {
    margin-bottom: 64px;
  }

  &__heading {
    h3 {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 44px;
      line-height: 33px;
      color: #1b3155;
      @media only screen and (max-width: $bp-medium) {
        font-size: 32px;
        line-height: 28px;
      }
    }
  }

  &__container {
    max-width: 1024px;
    margin: 0 auto;
    padding-bottom: 40px;
    @media only screen and (max-width: 480px) {
      padding-bottom: 0px;
    }
  }

  &__container-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 1030px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 35px;
    }

    @media only screen and (max-width: 700px) {
      .children {
        img {
          max-width: 90px !important;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 35px;

      .children {
        margin-right: 0 !important;
      }
    }

    .children {
      margin-right: 26px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:first-child {
      width: 85%;
      margin: 0 auto;
      padding-bottom: 24px;
      padding-top: 48px;

      @media screen and (max-width: 600px) {
        padding-bottom: 0px;
        width: 100%;
        margin: 0;
      }
    }
  }
}
