.header-container {
  max-width: $bp-largest;
  margin: 0 auto;
  padding: 64px 104px 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: $bp-large) {
    padding: 24px 24px 40px;
  }

  @media only screen and (max-width: $bp-small) {
    padding: 14px 24px 40px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__nav {
    list-style: none;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: $bp-medium) {
      flex-direction: column;
      align-items: start;
      margin: 0 24px;
    }
    &-link {
      display: inline-block;

      a {
        position: relative;
        text-decoration: none;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $color-grey-800;
        margin-right: 36px;
        @media only screen and (max-width: 1136px) {
          margin-right: 24px;
        }
        @media only screen and (max-width: $bp-medium) {
          margin-bottom: 24px;
        }
      }

      a:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: -8px;
        border-radius: 32px;
        background-color: #6b57b8;
        visibility: hidden;
        display: none;
        transform: scaleX(0);
        transition: all 0.3s ease-in;
      }
      @media screen and (max-width: $bp-medium) {
        a:before {
          content: url("../../assets/arrow-right.svg");
          background-color: transparent;
          top: 0;
          height: 0;
          position: relative;
        }
      }

      a:hover {
        color: $color-purple-800;
      }
      a:hover:before {
        display: inline;
        visibility: visible;
        transform: scaleX(1);
        margin-right: 13px;
      }
      &-icon {
        display: none;
        &:hover {
          display: block;
        }
      }
    }
  }

  .button-link {
    @media only screen and (max-width: $bp-medium) {
      width: 100%;
      padding-right: 24px;
    }
  }
  &__button {
    @include button;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 24px;
    background: linear-gradient(100.67deg, #d506d6 0%, #6b57b8 100%);
    border-radius: 24px;
    align-self: flex-start;

    .button-text {
      margin-right: 13px;
    }
    &:hover {
      background: linear-gradient(100.67deg, #812682 0%, #342279 100%);
      transition: all 0.3s;
    }
    @media only screen and (max-width: $bp-medium) {
      font-size: 14px;
      line-height: 20px;
      padding: 8px 16px;
      align-self: center;
      width: 100%;
      margin-bottom: 53px;
      justify-content: center;
      .button-text {
        margin-right: 9px;
      }
    }
    @media only screen and (max-width: $bp-small) {
      margin-bottom: 44px;
    }
  }

  .logo {
    @media only screen and (max-width: $bp-medium) {
      width: 140px;
      img {
        width: 100%;
      }
    }
    @media only screen and (max-width: $bp-small) {
      width: 118px;
      img {
        width: 100%;
      }
    }
  }
}

.notdisplayed {
  display: none;
}

.displayed {
  display: none;
  @media screen and (max-width: $bp-medium) {
    display: inline-block;
    margin-right: 13px;
  }
}

.mobile-logo {
  position: absolute;
  left: 24px;
  top: 24px;
  @media screen and (max-width: $bp-small) {
    top: 12px;
  }
  img {
    width: 140px;
    @media screen and (max-width: $bp-small) {
      width: 118px;
      top: 12px;
    }
  }
  @media screen and (min-width: $bp-medium) {
    display: none;
  }
}
