.blog-container {
  max-width: $bp-largest;
  margin: 0 auto;
  padding: 0px 104px 107px 104px;

  @media only screen and (max-width: $bp-large) {
    padding: 0px 32px 0 32px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0px 33px 0 33px;
  }
  @media only screen and (max-width: $bp-small) {
    padding: 0px 24px;
    grid-template-columns: 1fr;
  }
  &__headings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 64px;
    @media only screen and (max-width: $bp-medium) {
      margin-top: 32px;
      margin-bottom: 0px;
    }

    &-main {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 38px;
      color: $color-grey-800;
      @media only screen and (max-width: $bp-medium) {
        font-size: 20px;
        line-height: 28px;
      }
    }
    &-guides {
      a {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: $color-purple-800;
        text-decoration: none;
        &:hover {
          color: #5c2f97;
          transition: all 0.3s;
        }
      }

      display: flex;
      align-items: center;
      @media only screen and (max-width: $bp-medium) {
        display: none;
      }
    }
  }

  .blog {
    display: flex;
    flex-direction: row;

    .blog-card:not(:last-child) {
      margin-right: 40px;
    }

    &-card {
      border-radius: 8px;
      background: #ffffff;
      border: 1px solid $color-grey-200;
      box-sizing: border-box;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08),
        0px 8px 20px rgba(0, 0, 0, 0.12);

      &__image {
        // width: 384px;
        border-radius: 8px 8px 0px 0px;
        margin: 0;
        img {
          width: 100%;
          border-radius: 8px 8px 0px 0px;
        }
      }
      &__info {
        margin: 24px 24px 50px;
        @media only screen and (max-width: $bp-medium) {
          margin: 24px 24px 24px;
        }
        &-heading {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 33px;
          margin-bottom: 16px;
          color: $color-grey-800;
          @media only screen and (max-width: $bp-medium) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        &-text {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $color-grey-800;
          @media only screen and (max-width: $bp-medium) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        a {
          text-decoration: none;
        }
        &-button {
          background: $color-grey-300;
          border-radius: 45px;
          border: none;
          padding: 8px 16px;
          margin-top: 24px;
          color: $color-purple-800;
          height: 36px;
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          color: $color-purple-800;
          text-decoration: none;
          cursor: pointer;
          @media only screen and (max-width: $bp-medium) {
            margin-top: 16px;
            font-size: 12px;
            line-height: 16px;
          }
          &:hover {
            background-color: $color-grey-400;
            transition: all 0.3s;
          }
          a:hover {
            color: $color-purple-900 !important;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .carousel-container {
    width: 100%;
    height: 680px;

    @media only screen and (max-width: 790px) {
      height: 620px;
    }
    @media only screen and (max-width: 700px) {
      height: 580px;
    }
    @media only screen and (max-width: 600px) {
      height: 550px;
    }
    @media only screen and (max-width: 500px) {
      height: 500px;
    }
  }

  .react-multi-carousel-dot--active button {
    background: $color-purple-800 !important;
  }

  .react-multi-carousel-dot button {
    border-color: transparent;
    background: $color-grey-400;
    width: 6px;
    height: 6px;
  }
  .react-multi-carousel-item {
    padding: 0 20px;
    @media only screen and (max-width: $bp-smallest) {
      padding: 0 5px;
    }
  }
}
