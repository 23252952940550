.section {
  margin-top: -350px;
  padding-bottom: 66px;
  @media only screen and (min-width: $bp-largest) {
    margin-top: -450px;
  }

  @media screen and (max-width: 875px) {
    &.info {
      padding-bottom: 24px;
    }
  }

  .info-container {
    max-width: $bp-largest;
    margin: 0 auto;
    padding: 0px 104px 0px 104px;
    @media only screen and (max-width: $bp-large) {
      padding: 0px 32px 0px 32px;
    }

    @media only screen and (max-width: $bp-medium) {
      padding: 0px 33px 0px 33px;
    }
    @media only screen and (max-width: $bp-small) {
      padding: 0px 24px;
      grid-template-columns: 1fr;
    }
    .info-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 77px;
      @media only screen and (max-width: $bp-medium) {
        margin-bottom: 32px;
      }

      :not(:last-child) {
        margin-right: 40px;
        @media only screen and (max-width: $bp-large) {
          margin-right: 20px;
        }
        @media only screen and (max-width: $bp-medium) {
          margin-right: 10px;
        }
        @media only screen and (max-width: 865px) {
          margin-right: 0;
        }
      }
      .info {
        width: 384px;
        min-width: 250px;
        background: #ffffff;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08),
          0px 8px 20px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        padding: 24px;
        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          height: 100%;
        }
        &-image {
          display: flex;
          justify-content: center;
          margin: 0 auto;
        }
        &-title {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 27px;
          color: $color-grey-900;
          padding-top: 34px;
          margin-bottom: 16px;
          @media only screen and (max-width: $bp-medium) {
            font-size: 16px;
            line-height: 20px;
          }
        }
        &-text {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          color: $color-grey-900;
          @media only screen and (max-width: $bp-medium) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
    .integrations {
      text-align: center;
      &__heading {
        h3 {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 900;
          font-size: 44px;
          line-height: 33px;
          color: $color-grey-800;
          @media only screen and (max-width: $bp-medium) {
            font-size: 40px;
            line-height: 28px;
          }
        }

        margin-bottom: 33px;
      }
      &__images {
        display: flex;
        justify-content: space-between;
        padding: 0 130px;
        @media only screen and (max-width: $bp-medium) {
          padding: 0;
        }
        img {
          width: 100%;
        }
      }
      &__button {
        margin-top: 35px;
        @media only screen and (max-width: 768px) {
          margin-top: 19px;
        }

        a {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: $purple-800;
          outline: none;
          background: none;
          text-decoration: none;
          display: inline-flex;
        }
        a:hover {
          color: $purple-900;
          stroke: $purple-900;
          svg:hover {
            stroke: $purple-900;
          }
        }
        img {
          margin-left: 12px;
        }
      }
    }
  }
  .carousel-container {
    width: 100%;
    height: 350px;
    @media only screen and (max-width: $bp-small) {
      height: 380px;
    }

    @media only screen and (max-width: $bp-smallest) {
      height: 400px;
    }
  }

  .react-multi-carousel-dot--active button {
    background: $color-purple-800 !important;
  }

  .react-multi-carousel-dot button {
    width: 6px;
    height: 6px;
    border-color: transparent;
    background: $color-grey-400;
  }
  .react-multi-carousel-item {
    padding: 0 20px;
    @media only screen and (max-width: $bp-smallest) {
      padding: 0 5px;
    }
  }
}

.featured {
  background: #09aacf;
  text-align: center;
  padding: 32px;
  padding-bottom: 48px;
  margin-top: 50px;

  &.enterprise {
    margin-top: 28px;
  }

  @media screen and (max-width: 600px) {
    padding: 24px;
    padding-bottom: 23px;
  }

  @media screen and (max-width: 875px) {
    &.enterprise {
      margin-top: 7px !important;
      margin-bottom: 48px !important;
    }

    &.info {
      margin-top: 18px;
    }
  }

  &.enterprise {
    margin-bottom: 64px;
  }

  &__heading {
    h3 {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 44px;
      line-height: 33px;
      color: #fff;
      @media only screen and (max-width: $bp-medium) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  &__container {
    max-width: 1024px;
    margin: 0 auto;
  }

  &__container-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 1030px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 35px;
    }

    @media only screen and (max-width: 700px) {
      .children {
        img {
          max-width: 90px !important;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 35px;

      .children {
        margin-right: 0 !important;
      }
    }

    .children {
      margin-right: 26px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:first-child {
      width: 85%;
      margin: 0 auto;
      padding-bottom: 33px;
      padding-top: 33px;

      @media screen and (max-width: 600px) {
        padding-bottom: 18px;
        padding-top: 24px;
      }
    }
  }
}
