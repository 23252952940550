// COLORS
$color-primary-green: #204231;

$color-grey-900: #071b3c;
$color-grey-800: #1b3155;
$color-grey-500: #8d98aa;
$color-grey-300: #e9ebee;
$color-grey-400: #d7dfe7;
$color-grey-200: #e8eaee;
$color-grey-1000: #041027;
$grey-100: #f6f7f8;

$color-primary-pink: #d506d6;
$color-purple-900: #5c2f97;
$color-purple-800: #6b57b8;
$color-purple-500: #9d86f8;
$color-white-primary: #fff;
$color-white-secondary: #e5e5e5;
$color-white-tertiary: #f6f7f8;
$color-black-primary: #292929;
$blue-900: #0f91ae;
$blue-500: #06b5dd;
$purple-900: #5c2f97;
$purple-800: #6b57b8;
$color-red: #ef4d4d;

// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 144rem;

// RESPONSIVE BREAKPOINTS
$bp-largest: 1440px;
$bp-large: 1024px;
$bp-medium: 876px;
$max-width: 1000px;
$bp-small: 550px;
$bp-smallest: 360px;

$slide-count: 5;
$slide-width: 30rem;
$slide-width-mobile: 30rem;
