@import url("https://fonts.googleapis.com/css?family=Nunito+Sans");
@import "react-multi-carousel/lib/styles.css";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  background-color: $color-white-primary;
  margin: 0 auto;
  font-family: "Nunito Sans", sans-serif;
  font-size: $default-font-size;
  font-style: normal;
  font-weight: normal;

  &.no-scroll {
    overflow: hidden;
  }
}
.button-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
