@mixin button {
  font-family: "Nunito Sans", sans-serif;
  color: $color-white-primary;

  outline: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  a {
    text-decoration: none;
    color: $color-white-primary;
    text-decoration: none;
    cursor: pointer;
  }
}
