.feature-section {
  margin: 0 auto;

  .feature-container {
    max-width: $bp-largest;
    margin: 0 auto;

    padding: 0px 104px 0px 104px;

    @media only screen and (max-width: $bp-large) {
      padding: 0px 32px 0px 32px;
    }

    @media only screen and (max-width: $bp-medium) {
      padding: 0px 33px 0px 33px;
    }
    @media only screen and (max-width: $bp-small) {
      padding: 0px 24px;
      grid-template-columns: 1fr;
    }
    &__heading {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 44px;
      line-height: 46px;
      text-align: center;
      color: $color-grey-800;
      margin-bottom: 54px;
      @media only screen and (max-width: $bp-medium) {
        font-size: 40px;
        line-height: 28px;
      }
    }
  }

  .react-multiple-carousel__arrow {
    top: 0;
    min-width: 23px;
    min-height: 23px;
    background: transparent;
    border: 2px solid $color-purple-800;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 14px;
    color: $color-purple-800;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feature-image-container {
    background-image: url("/../src/assets/feature-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -390px;
    height: 39rem;
    overflow: hidden;
    @media only screen and (max-width: $bp-large) {
      height: 28.5rem;
    }
    @media only screen and (max-width: 965px) {
      height: 23.4rem;
    }
    @media only screen and (max-width: $bp-medium) {
      background-image: url("/../src/assets/feature-bg-mobile.svg");
      height: 10.3rem;
      margin-top: -190px;
    }

    img {
      height: 100%;
      background-size: auto auto;

      @media only screen and (max-width: $bp-small) {
        width: 100%;
      }

      @media only screen and (min-width: $bp-largest) {
        width: 100%;
        height: 100%;
        background-size: contain;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .carousel__wrap {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .carousel__container {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .carousel__slide-list {
    height: 100%;
    left: 22%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    transform: translateX(-50%);
    width: calc(#{($slide-count + 0.5) * $slide-width * 2});
    @media only screen and (max-width: $bp-large) {
      left: 29%;
    }
    @media only screen and (max-width: 965px) {
      left: 34%;
    }
    @media only screen and (max-width: $bp-medium) {
      left: 35%;

      width: calc(#{($slide-count + 0.5) * $slide-width-mobile * 2});
    }

    @media only screen and (max-width: 550px) {
      left: 50%;
    }
  }

  .carousel__slide-item-img-link {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0px 12px 12px rgba(27, 49, 85, 0.08),
      0px 2px 8px rgba(27, 49, 85, 0.2);

    img {
      width: 100%;
      object-fit: cover;
      transition: all 0.5s ease;
      margin: 0 auto;
    }
  }

  .carousel-slide-item__body {
    bottom: -2.5rem;

    @media only screen and (max-width: 550px) {
      height: 62px;
    }

    h4 {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: $color-grey-800;
      padding-bottom: 64px;
      @media only screen and (max-width: $bp-medium) {
        font-size: 14px;
        line-height: 16px;
        padding-bottom: 34px;
        padding-right: 2rem;
      }
    }
  }

  .carousel__btn {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0px;
    transform: translateY(-50%);
    padding: 5px;
    border-radius: 50%;
    stroke: $color-purple-800;
    @media only screen and (min-width: $bp-medium) {
      top: 10px;
    }

    &--prev {
      left: -10rem;
      @media only screen and (max-width: $bp-medium) {
        left: 0rem;
      }
    }

    &--next {
      right: -10rem;
      @media only screen and (max-width: $bp-medium) {
        right: 0rem;
      }
    }
  }

  .carousel__btn-arrow {
    border: solid $color-purple-800;
    border-width: 0 0.25rem 0.25rem 0;
    height: 10px;
    padding: 3px;
    width: 10px;
    z-index: 1;

    &--left {
      transform: rotate(135deg);
    }

    &--right {
      transform: rotate(-45deg);
    }
  }

  .previous-text {
  }
}

.feature-section .carousel__inner {
  height: 50rem;
  position: relative;
  width: calc(#{$slide-width * 3});

  @media only screen and (max-width: $bp-large) {
    width: calc(#{$slide-width * 2 + 10}) !important;
  }
  @media only screen and (max-width: 965px) {
    height: 50rem;
    width: calc(#{$slide-width * 2}) !important;
  }

  @media only screen and (max-width: $bp-medium) {
    height: 38.6rem;
    // width: calc(#{$slide-width-mobile * 1.5});
  }

  @media only screen and (max-width: 550px) {
    height: 30rem;
  }
}

.feature-section .carousel__slide-item {
  display: inline-block;
  height: $slide-width + 12rem;
  margin: 0;
  position: absolute;
  transition: all 0.3s;
  width: calc(#{$slide-width * 2 + 20rem});
  @media only screen and (max-width: $bp-large) {
    height: $slide-width;
    width: calc(#{$slide-width * 2});
  }

  @media only screen and (max-width: 965px) {
    height: calc(#{$slide-width - 5rem});
    width: calc(#{$slide-width + 20rem});
  }

  @media only screen and (max-width: 550px) {
    height: calc(#{$slide-width - 15rem});
    width: $slide-width;
  }
}

.carousel-next__item,
.carousel-previous__item {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $color-purple-800;
  width: 173px;
  z-index: 999999999999;
}
.next-item {
  top: 90px;
  right: 22rem;
  @media only screen and (max-width: 1300px) {
    right: 12rem;
  }
  @media only screen and (max-width: 1243px) {
    right: 10rem;
  }
  @media only screen and (max-width: 1186px) {
    right: 7rem;
  }
  @media only screen and (max-width: 1106px) {
    right: 4rem;
  }
  @media only screen and (max-width: $bp-large) {
    display: none;
  }
}

.previous-item {
  top: 90px;
  left: 22rem;
  @media only screen and (max-width: 1300px) {
    left: 12rem;
  }
  @media only screen and (max-width: 1243px) {
    left: 10rem;
  }
  @media only screen and (max-width: 1186px) {
    left: 7rem;
  }
  @media only screen and (max-width: 1106px) {
    left: 4rem;
  }
  @media only screen and (max-width: $bp-large) {
    display: none;
  }
}

.previous-item__button {
  cursor: pointer;
  :hover,
  :focus {
    text-decoration: underline;
  }
}

.next-item__button {
  cursor: pointer;
  :hover,
  :focus {
    text-decoration: underline;
  }
}
