.vision-container {
  max-width: $bp-largest;
  margin: 0 auto;
  padding: 0px 104px 205px 104px;

  .policy-text {
    padding-top: 25px;
    &:first-child {
      padding-top: 10px;
    }
  }

  .distribution-info_ul {
    margin-left: 20px;
    color: $color-grey-800;

    &.bottom {
      margin-bottom: 24px;
    }

    li {
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
  }

  @media only screen and (max-width: $bp-large) {
    padding: 0px 32px 205px 32px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0px 33px 32px 33px;
  }
  @media only screen and (max-width: $bp-small) {
    padding: 0px 24px;
    grid-template-columns: 1fr;
  }

  .membership-header {
    margin-bottom: 89px;
    @media screen and (max-width: $max-width) {
      margin-bottom: 32px;
    }
  }
  &__heading {
    font-family: Nunito Sans;
    font-weight: 900;
    font-size: 32px;
    line-height: 44px;
    color: $color-grey-800;
    margin-bottom: 4px;
    @media screen and (max-width: $bp-medium) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $color-grey-800;
    margin-bottom: 49px;

    @media screen and (max-width: $bp-medium) {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  .token {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: $color-white-tertiary;
    border: 1px solid $color-grey-200;
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08),
      0px 8px 20px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-bottom: 64px;
    @media screen and (max-width: $bp-medium) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 27px;
    }
    &-price {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: $bp-medium) {
        margin-bottom: 30px;
      }

      &__heading {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 25px;
        color: $color-grey-800;
        margin-bottom: 4px;
        @media screen and (max-width: $bp-medium) {
          font-size: 16px;
          line-height: 22px;
        }
      }
      &__value {
        background: $color-grey-400;
        border-radius: 45px;
        padding: 4px 12px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: $color-grey-800;
        width: fit-content;
      }
    }
    &-button {
      display: flex;
      @media screen and (max-width: $bp-medium) {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
      a {
        text-decoration: none;
        @media screen and (max-width: $bp-medium) {
          width: 100%;
        }
      }
      .button {
        @include button;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 24px;
        background: $color-purple-800;
        border-radius: 24px;
        align-self: flex-start;
        white-space: nowrap;

        .button-text {
          margin-right: 13px;
        }
        &:hover {
          background-color: $color-purple-900;
          transition: all 0.3s;
        }
        @media only screen and (max-width: $bp-medium) {
          font-size: 14px;
          line-height: 20px;
          padding: 8px 16px;
          align-self: center;
          width: 100%;
          margin-bottom: 16px;
          justify-content: center;

          .button-text {
            margin-right: 9px;
          }
        }
        @media only screen and (max-width: $bp-small) {
          margin-bottom: 16px;
        }
      }
      .uniswap-button {
        margin-right: 16px;
        @media only screen and (max-width: $bp-medium) {
          margin-right: 0;
          justify-content: center;
        }
      }
    }
  }
  .distribution {
    &-info {
      grid-column: 1;
      grid-row: 1;
      @media only screen and (max-width: $bp-medium) {
        grid-row: 2;
      }
      &__content {
        margin-bottom: 32px;
        &-heading {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 30px;
          display: flex;
          align-items: center;
          color: $color-grey-800;
          margin-bottom: 16px;

          @media only screen and (max-width: $bp-medium) {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &-text {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: $color-grey-800;
          margin-bottom: 16px;
          @media only screen and (max-width: $bp-medium) {
            font-size: 14px;
            line-height: 19px;
          }
        }
        &-list {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: $color-grey-800;
          list-style-position: outside;
          margin-left: 1em;
          @media only screen and (max-width: $bp-medium) {
            font-size: 14px;
            line-height: 19px;
          }

          li::marker {
            color: $color-grey-800;
          }

          li:first-child::marker {
            font-size: 16px;
          }
        }
        &-note {
          font-family: Nunito Sans;
          font-style: italic;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: $color-grey-500;
          margin-bottom: 24px;
          @media only screen and (max-width: $bp-medium) {
            font-size: 12px;
            line-height: 16px;
          }
        }
        .button {
          @include button;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          padding: 10px 24px;
          background: $blue-500;
          border-radius: 24px;
          align-self: flex-start;
          white-space: nowrap;

          .button-text {
            margin-right: 13px;
          }
          &:hover {
            background-color: $blue-900;

            transition: all 0.3s;
          }
          @media only screen and (max-width: $bp-medium) {
            display: none;
          }
        }
      }
    }
    &-chart {
      grid-column: 2;
      grid-row: 1;
      width: 100%;
      @media only screen and (max-width: $bp-medium) {
        grid-column: 1;
      }
      img {
        width: 100%;
        padding-top: 50px;
        @media only screen and (max-width: $bp-medium) {
          padding-top: 0;
        }
      }
      &__desktop {
        @media only screen and (max-width: $bp-medium) {
          display: none;
        }
      }
      &__mobile {
        margin: 0 auto;
        display: flex;
        justify-self: center;
        flex-direction: column;
        margin-bottom: 30px;
        @media only screen and (min-width: $bp-medium) {
          display: none;
        }

        &-heading {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          color: $color-grey-800;
          text-align: center;
        }
        &-image {
          width: 312px;
          margin: 0 auto;
          @media only screen and (max-width: $bp-small) {
            width: 250px;
          }
        }
      }
    }
  }
}
