.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100vw;
  height: 100vh;
  /* grey-1000 */

  background: $color-grey-1000;
  opacity: 0.7;
}

.modal-wrapper {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  width: 640px;

  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: $color-white-primary;
  /* shadow/md/light */

  box-shadow: 0px 0px 3px rgba(27, 49, 85, 0.16),
    0px 4px 8px rgba(27, 49, 85, 0.24);
  border-radius: 8px;
  @media screen and (min-width: $bp-largest) {
    top: 75%;
  }
  @media screen and (min-width: $bp-large) {
    top: 85%;
  }
  @media screen and (max-width: $max-width) {
    max-width: 550px;
    min-width: 340px;
    top: 120%;
  }
  @media screen and (max-width: $bp-small) {
    max-width: 100%;
    top: 110.5%;
  }
  @media screen and (max-width: $bp-smallest) {
    max-width: 100%;
    top: 103.5%;
  }
}

.modal-close-button {
  position: absolute;
  opacity: 1;
  background: none;
  padding: 18px 18px 0 0;
  float: right;
  right: 0;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.load-wallet {
  display: flex;
  flex-direction: column;
  width: 465px;
  align-items: center;
  margin: 0 auto;
  padding-top: 70px;
  .load-wallet-button {
    width: 100%;
    margin-top: 8px;
    font-weight: bold;
  }
  .load-wallet-input {
    width: 100%;
  }
}

.calculator-info {
  margin-top: 52px;
  margin-bottom: 32px;
  @media screen and (max-width: $max-width) {
    margin-top: 72px;
  }

  &__heading {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: $color-grey-800;
    margin-bottom: 11px;
    @media screen and (max-width: $max-width) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__form {
    text-align: center;
    margin-bottom: 50px;
    position: relative;
    @media screen and (max-width: $max-width) {
      margin-bottom: 34px;
    }
    input {
      display: inline;
      border: 1px solid $color-grey-500;

      box-sizing: border-box;
      border-radius: 48px;
      height: 44px;
      width: 320px;
      padding-left: 16px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color-grey-800;
      @media screen and (max-width: $max-width) {
        height: 36px;
        width: 312px;
      }

      &:focus {
        outline: none;
        border-color: $color-purple-800;
        box-shadow: 0 0 0 1px $color-purple-800;
      }
      &::placeholder {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-grey-800;
      }
    }

    span {
      margin-left: -60px;
      display: inline;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      background: $grey-100;
      border-top-right-radius: 48px;
      border-bottom-right-radius: 48px;
      color: $color-grey-800;
      padding: 9.2px 12px;
      @media screen and (max-width: $max-width) {
        margin-left: -55px;
        font-size: 14px;
        line-height: 20px;
        padding: 6.5px 12px;
      }
    }
  }
  &__pools {
    margin: 0 16px;
    padding-top: 20px;
    @media screen and (max-width: $max-width) {
      padding-top: 5px;
    }
  }
  &__pool {
    background: #f8f7ff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    padding: 8px 0;
    @media screen and (max-width: $max-width) {
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
    &-details {
      margin-right: 24px;

      @media screen and (max-width: $max-width) {
        margin-right: 0;
        margin-bottom: 18px;
      }
      &_name {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
        color: $color-grey-800;
        a {
          color: $color-purple-800;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      &_chain {
        display: flex;
        align-items: center;
        &-image {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          img {
            width: 100%;
          }
        }
        p {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          display: flex;
          align-items: center;
          text-align: right;

          /* grey-500 */

          color: $color-grey-500;
        }
      }
    }

    &-tokens {
      h2 {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $color-purple-800;
        margin-bottom: 4px;
      }
      h5 {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
        color: $color-grey-800;
      }
      span {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $color-grey-800;
      }
    }
    .bonus {
      position: absolute;
      right: 8px;
      top: 8px;
      background: linear-gradient(
        100.67deg,
        $color-primary-pink 0%,
        $color-purple-800 100%
      );
      border-radius: 4px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $color-white-primary;
      padding: 4px 8px;
      @media screen and (max-width: $max-width) {
        display: none;
      }
    }
  }
  &__or {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey-500;
    margin: 8px 0;
  }
  &__notice {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $color-grey-500;
    margin-top: 16px;
    margin-bottom: 36px;
    padding: 0 20px;
    @media screen and (max-width: $max-width) {
      margin-top: 22px;
      margin-bottom: 38px;
    }
  }
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 12px;
    }
    .token-buttons {
      display: flex;
      flex-direction: row;
      margin-bottom: 32px;
      @media screen and (max-width: $max-width) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 24px;
        margin-bottom: 0px;
      }
      :first-child {
        margin-right: 16px;
        @media screen and (max-width: $max-width) {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
      .button {
        @include button;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 24px;
        background: $color-purple-800;
        border-radius: 24px;
        align-self: flex-start;
        white-space: nowrap;
        margin-left: 0px;
        margin-right: 12px;

        .button-text {
          margin-right: 13px;
        }
        &:hover {
          background-color: $color-purple-900;
          transition: all 0.3s;
        }
        @media only screen and (max-width: $max-width) {
          font-size: 14px;
          line-height: 20px;
          padding: 8px 16px;
          align-self: center;
          width: 100%;
          margin-bottom: 53px;
          margin-right: 0;
          .button-text {
            margin-right: 9px;
          }
        }
        @media only screen and (max-width: $max-width) {
          margin-bottom: 16px;
        }
      }
    }
    .button-width {
      width: 156px;
      place-content: center;
    }
  }
}
