.hero-container {
  max-width: $bp-largest;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0px 104px 0 104px;

  @media only screen and (max-width: $bp-large) {
    padding: 0px 32px 0 32px;
  }

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: 85% 15%;

    padding: 0px 33px 0 33px;
  }
  @media only screen and (max-width: $bp-small) {
    padding: 0px 24px;
    grid-template-columns: 1fr;
  }

  .hero {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    @media only screen and (min-width: $bp-small) {
      padding-right: 32px;
    }

    &-heading {
      font-family: "Nunito Sans", sans-serif;
      font-size: 48px;
      line-height: 45px;
      font-weight: 900;
      margin-bottom: 25px;
      color: $color-grey-800;

      &-color {
        background: linear-gradient(
          to right,
          $color-primary-pink 0%,
          $color-purple-800 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media only screen and (max-width: $bp-medium) {
        font-size: 35px;
        line-height: 29px;
      }
    }

    &-text {
      color: $color-grey-800;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 44px;
      @media only screen and (max-width: $bp-medium) {
        display: none;
      }
    }
    &-button {
      @include button;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 24px;
      background: linear-gradient(100.67deg, #d506d6 0%, #6b57b8 100%);
      border-radius: 24px;
      align-self: flex-start;
      margin-bottom: 90px;
      height: 44px;
      .button-text {
        margin-right: 13px;
      }
      &:hover {
        background: linear-gradient(100.67deg, #812682 0%, #342279 100%);
        transition: all 0.3s;
      }
      @media only screen and (max-width: $bp-medium) {
        font-size: 14px;
        line-height: 20px;
        padding: 8px 16px;

        margin-bottom: 53px;
        .button-text {
          margin-right: 9px;
        }
      }
      @media only screen and (max-width: $bp-small) {
        margin-bottom: 44px;
      }
    }
  }
  .flex {
    flex: 1 1 auto;
  }
}

.image-container {
  background-image: url("/../src/assets/hero.svg");

  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -400px;
  height: 1000px;
  overflow: hidden;
  background-position: center;

  @media only screen and (max-width: $bp-largest) {
    height: 900px;
  }

  @media only screen and (min-width: $bp-largest) {
    margin-top: -450px;
    height: 1200px;
  }

  @media only screen and (max-width: $bp-medium) {
    background-image: url("/../src/assets/hero-mobile.svg");
    margin-top: -250px;
    height: 700px;
  }

  @media only screen and (max-width: $bp-small) {
    margin-top: -280px;
    height: 680px;
  }

  .hero-image__desktop {
    display: block;
  }

  img {
    height: 100%;
    // background-position: left center;
    background-size: auto auto;

    @media only screen and (max-width: $bp-small) {
      width: 100%;
      height: 100%;
    }

    @media only screen and (min-width: $bp-largest) {
      width: 100%;
      height: 100%;
      background-size: contain;
    }
  }
}
