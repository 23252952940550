.membership-container {
  max-width: $bp-largest;
  margin: 0 auto;
  padding: 0px 104px 0px 104px;

  @media screen and (max-width: 1210px) {
    padding: 0px 32px 205px 32px;
  }
  @media only screen and (max-width: $bp-large) {
    padding: 0px 32px 205px 32px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0px 33px 32px 33px;
  }
  @media only screen and (max-width: $bp-small) {
    padding: 0px 24px 24px;
    grid-template-columns: 1fr;
  }

  .carouselBtn {
    padding-bottom: 24px !important;
  }

  .nft-button {
    margin-bottom: 32px;
    margin-top: 24px;
    &.blue {
      background: linear-gradient(
        100.67deg,
        #d506d6 0%,
        #6b57b8 100%
      ) !important;
    }
  }
  .membership-container-subscriptions-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .desc {
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .subscription_portfolio {
    width: 100%;
    max-width: 610px;
    padding: 24px;
    border: 2px solid #e8eaee;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 16px;

    &:first-child {
      margin-top: 0px;
    }

    .containerUL {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }

      ul {
        list-style: none;

        img {
          margin-right: 10px;
        }
        li {
          font-size: 14px;
          margin-bottom: 8px;
          color: #1b3155;
        }
      }
    }

    .title {
      color: #1b3155;
      margin-bottom: 20px;
      font-size: 18px;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .subscription-help {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(100.67deg, #d506d6 0%, #6b57b8 100%);
    height: 110px;
    padding: 24px;
    border-radius: 8px;
    margin-top: 66px;
    margin-bottom: 64px;
    @media screen and (max-width: 876px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: auto;
      margin-top: 34px;
      margin-bottom: 8px;

      .title {
        margin-bottom: 16px;
        text-align: center;
      }
    }

    .title {
      font-size: 28px;
      color: #fff;
      font-weight: 900;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    .button {
      @include button;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 24px;
      background: #06b5dd;
      border-radius: 24px;
      white-space: nowrap;
      .button-text {
        margin-right: 13px;
      }
    }
  }

  .subscription {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding-top: 64px;

    @media screen and (max-width: 876px) {
      padding-top: 34px;
    }

    .title {
      color: #1b3155;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    .subscription-check {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6b57b8;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;

      img {
        margin-left: 8px;
      }
    }
  }

  .vision {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding-top: 64px;

    @media screen and (max-width: 1000px) {
      padding-top: 45px;
    }

    .title {
      color: #1b3155;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 8px;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    .titleTwo {
      color: #1b3155;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 32px;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    .paragraph {
      color: #8d98aa;
      font-size: 12px;
      margin-bottom: 12px;
      font-weight: 700;
    }

    .vision-btns {
      display: flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;

        a {
          width: 100%;
        }
      }

      .button {
        @include button;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 24px;
        background: $color-purple-800;
        border-radius: 24px;
        align-self: flex-start;
        white-space: nowrap;
        margin-left: 0px;
        margin-right: 12px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .button-text {
          margin-right: 13px;
        }
        &:hover {
          background-color: $color-purple-900;
          transition: all 0.3s;
        }
        @media only screen and (max-width: $bp-medium) {
          font-size: 14px;
          line-height: 20px;
          padding: 8px 16px;
          align-self: center;
          width: 100%;
          height: 36px;
          margin-bottom: 53px;
          .button-text {
            margin-right: 9px;
          }
        }
        @media only screen and (max-width: $bp-medium) {
          margin-bottom: 16px;
        }
      }
    }
  }

  .membership-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 66px;

    .membership-container-subscriptions {
      margin-top: 34px;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 480px;
      border: 1px solid #d7dfe7;
      border-radius: 6px;

      @media screen and (max-width: 767px) {
        margin-top: 24px;
      }

      .membership-child {
        width: 100%;
        background: #fff;
        color: #1b3155;
        height: 40px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }

        &.active {
          background: #e9ebee;
        }
      }
    }
    @media screen and (max-width: $max-width) {
      margin-bottom: 32px;
    }
  }

  .membership-container-sufficient {
    margin-bottom: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #1b3155;

    @media screen and (max-width: 767px) {
      margin-bottom: 32px;
    }

    &.nft {
      margin-bottom: 36px;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    .paragraph {
      font-size: 16px;
      font-weight: 700;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .link {
    display: flex;
    align-items: center;
    justify-content: center;

    .plan-wrapper__info-list-track-image {
      margin-bottom: 0px !important;
    }
  }
  .unvisible {
    color: #d7dfe7;
  }
  .plan-limited-text {
    font-weight: 700;
    font-size: 18px;
    color: #1b3155;
    text-align: center;
    margin-bottom: 8px;
    @media screen and (max-width: 767px) {
      &.header {
        font-size: 14px !important;
      }
    }
    .black {
      font-weight: 900;
      &.blue {
        background: linear-gradient(100.67deg, #d506d6 0%, #6b57b8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &__heading {
    font-family: Nunito Sans;
    font-weight: 900;
    font-size: 32px;
    line-height: 44px;
    color: $color-grey-800;
    margin-bottom: 4px;
    text-align: center;

    @media screen and (max-width: $max-width) {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &__text-dark {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $color-grey-800;
    text-align: center;
    @media screen and (max-width: $max-width) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 32px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  &__text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $color-grey-800;
    margin-bottom: 24px;
    @media screen and (max-width: $max-width) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__disclaimer {
    max-width: 500px;
    font-family: Nunito Sans;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    color: #1b3155 !important;
    padding-top: 24px;
    margin: 0 auto;
    @media screen and (max-width: 1184px) {
      max-width: 400px;
    }
    @media screen and (max-width: $max-width) {
      max-width: 100%;
      padding-top: 8px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__disclaimernft {
    max-width: 650px;
    font-family: Nunito Sans;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    color: #1b3155 !important;
    padding-top: 24px;
    margin: 0 auto;
    @media screen and (max-width: 1184px) {
      max-width: 400px;
    }
    @media screen and (max-width: $max-width) {
      padding-top: 0px;
      margin-top: -10px;
    }
  }
  .plans-container {
    display: flex;
    align-items: flex-start;

    &.two {
      @media screen and (max-width: 876px) {
        align-items: center;
        justify-content: center;
      }
    }

    &.one {
      @media screen and (max-width: 1000px) {
        align-items: center;
        justify-content: center;
      }
    }

    .plan-wrapper {
      width: 100%;
      @media screen and (max-width: $max-width) {
        margin-bottom: 24px;
        border-radius: 8px;
      }

      background: #fff;
      border: 1px solid $color-grey-200;
      box-sizing: border-box;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08),
        0px 8px 20px rgba(0, 0, 0, 0.12);

      &__heading {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        height: 57px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;

        &.blue {
          background: linear-gradient(
            100.67deg,
            #d506d6 0%,
            #6b57b8 100%
          ) !important;
        }
      }
      .basic-title__mg {
        padding-bottom: 46px;
      }
      .plan-free__heading {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media screen and (max-width: $max-width) {
          border-top-right-radius: 8px;
        }
      }
      .plan-free__bg {
        background: #fff;
        color: #1b3155;
        border-bottom: 1px solid #e9ebee;
      }
      .subscribtion-border {
        border-top-right-radius: 8px;
        @media screen and (max-width: $max-width) {
          border-top-left-radius: 8px;
        }
      }

      .button-get-app {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;

        .get-app {
          padding: 10px 24px;
          font-weight: 700;
          color: #6b57b8;
          background: #e9ebee;
          border-radius: 45px;
          cursor: pointer;
        }
      }

      &__info {
        margin-bottom: 46px;

        @media screen and (max-width: 1000px) {
          margin-bottom: 0px;
        }
        &-new-sections {
          margin-top: 22px;
        }

        &-description {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-weight: 700;
          font-size: 14px;
          border-radius: 8px;
          background: #f6f7f8;
          width: 100%;
          color: #1b3155;
          height: 72px;
          max-width: 350px;
          margin: 24px auto;
          border: 1px solid #6b57b8;
          position: relative;

          &-bonus {
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(100.67deg, #d506d6 0%, #6b57b8 100%);
            font-weight: 700;
            font-size: 12px;
            height: 25px;
            color: #fff;
            width: 50px;
            border-radius: 4px;
            position: absolute;
            left: 50%;
            top: -13px;
            transform: translate(-50%, 0);
          }

          p {
            max-width: 320px;
          }
        }

        &-list-track {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 26px;
          margin-top: 16px;

          a {
            color: #1b3155;
          }

          &-image {
            margin-right: 10px;

            &-unvisible {
              width: 11px;
              height: 8px;
              background: transparent;
              margin-right: 10px;
            }
          }
        }

        &-enjoy {
          font-size: 16px;
          color: #8d98aa;
          text-align: center;
          margin-bottom: 50px;
        }

        &-main {
          background: -webkit-linear-gradient(#d506d6, #6b57b8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 900;
          font-size: 24px;

          &.two {
            font-size: 36px !important;
          }
        }

        &-title {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
          color: #1b3155;

          padding-top: 24px;
        }

        &-subtitle-no-margin {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: $color-purple-800;
          margin-bottom: 0px;
        }

        &-subtitle {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: $color-purple-800;
          margin-bottom: 27px;
        }

        &-text {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: $color-grey-500;
          padding-bottom: 8px;

          @media screen and (max-width: 767px) {
            padding-bottom: 20px;
          }

          &-onetime {
            text-align: center;
            color: #1b3155;
            font-size: 14px;
          }

          &.pro {
            margin-top: 24px;
          }
        }
        ul {
          list-style: none;
          text-align: center;
          margin-bottom: 14px;
          :not(:last-child) {
            margin-bottom: 12px;
          }
        }
        ul li {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: $color-grey-800;
        }
        &-notice {
          font-family: Nunito Sans;
          font-style: italic;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: $color-grey-500;
          padding-top: 8px;
          margin: 0 50px;
        }
      }

      &__experience {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: $color-purple-800;

        margin-bottom: 31px;
      }
    }
    .free-wrapper {
      border-radius: 8px;
      margin-right: 16px;
      @media screen and (max-width: $max-width) {
        border-radius: 8px;
      }
    }
    .subscription-wrapper {
      border-radius: 8px;
      @media screen and (max-width: $max-width) {
        border-radius: 8px;
      }
    }
    .button-nft {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-bottom: 31px;
      @media only screen and (max-width: $bp-medium) {
        margin-bottom: 20px;
      }
    }
    .button {
      @include button;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 24px;
      background: #06b5dd;
      border-radius: 24px;
      white-space: nowrap;
      .button-text {
        margin-right: 13px;
      }
    }

    .plan-token {
      background: #fff;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08),
        0px 8px 20px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      z-index: 2;
      margin-right: 16px !important;
      width: 100%;

      @media screen and (max-width: $max-width) {
        margin-top: 0px;
        width: 100%;
        margin-bottom: 24px;
      }

      .plan-wrapper__heading {
        background: $blue-500;
        border-radius: 8px 8px 0px 0px;
      }
      .plan-token-border {
        &__heading {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          color: $color-grey-800;
          margin: 0 20px;
          padding: 24px 32px 5px;

          &.two {
            padding-top: 16px !important;
          }
        }
      }
      .pro-tier {
        background: #ffffff;
        border: 1px solid $color-grey-200;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 16px;
        &__heading {
          padding: 14px 0;
          margin: 0 auto;
          border-bottom: 1px solid $color-grey-200;

          h2 {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            color: $color-grey-800;
            text-align: center;
            margin-bottom: 0px;
          }
          &-badge {
            background: #06b5dd;
            border-radius: 4px;
            color: white;
            text-transform: uppercase;
            padding: 2px 4px;
            font-size: 16px;
            line-height: 21px;
          }
        }

        .pro-tier__plan {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          @media screen and (max-width: $bp-small) {
            flex-direction: column;
          }

          &-options {
            padding-top: 12px;
            padding-bottom: 24px;
            text-align: center;
            @media screen and (max-width: $bp-small) {
              padding-top: 8px;
              padding-bottom: 8px;
            }

            &-title {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 24px;
              text-align: center;
              color: $color-purple-800;
              margin-bottom: 4px;
            }
            &-text {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: $color-purple-800;
            }
            &-price {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 16px;
              color: $color-grey-500;
            }
          }

          &-text {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: $color-grey-500;
            text-align: center;
          }
        }
        .text-includes {
          margin-bottom: 12px !important;
        }
        &__calculator {
          background: $grey-100;
          border: 1px solid $color-purple-800;
          box-sizing: border-box;
          border-radius: 8px;
          margin: 0 16px;
          padding: 16px 0;
          text-align: center;
          position: relative;
          margin-bottom: 24px;
          @media screen and (max-width: $bp-small) {
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
          }
          &-heading {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $color-grey-800;
            padding: 0 77px;
            margin: 0 auto;
            @media only screen and (max-width: $bp-largest) {
              padding: 0 37px;
            }
          }
          .calculator-button {
            @media screen and (max-width: $bp-medium) {
              width: 100%;
              display: flex;
              padding: 0 24px;
            }
          }

          &-list {
            padding-top: 8px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 479px;
            :not(:last-child) {
              margin-right: 16px;
            }
            @media screen and (max-width: $bp-medium) {
              flex-direction: column;
              :not(:last-child) {
                margin-right: 0;
              }
            }
            &__group {
              display: flex;
              flex-direction: row;
              align-items: center;
              :not(:last-child) {
                margin-right: 8px;
              }
            }
            &__text {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #1b3155;
              white-space: nowrap;

              span {
                font-weight: bold;
              }
            }

            &__image {
              width: 24px;
              margin-right: 0;
              @media screen and (max-width: $bp-medium) {
                margin-right: 8px !important;
              }
              img {
                width: 100%;
                border-radius: 50%;
                @media screen and (max-width: $bp-small) {
                  max-width: 150px;
                }
              }
            }
          }
          &-bonus {
            background: linear-gradient(
              100.67deg,
              $color-primary-pink 0%,
              $color-purple-800 100%
            );
            border-radius: 4px;
            display: inline-block;
            position: absolute;
            left: 14px;
            top: -14px;
            &-text {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 16px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #ffffff;
              padding: 4px 8px;
            }
          }
        }
        &__list {
          display: flex;
          justify-content: space-around;
          @media screen and (max-width: $bp-small) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          ul {
            list-style: none;
            text-align: center;
            margin-bottom: 14px;
            :not(:last-child) {
              margin-bottom: 12px;
            }
          }
          ul li {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: $color-grey-800;
            max-width: 170px;
          }
        }
      }
      .button-group {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          display: flex;
          align-items: center;
          text-align: center;
          margin-top: 8px;
          margin-bottom: 12px;
        }
        .token-buttons {
          display: flex;
          flex-direction: row;
          margin-bottom: 32px;
          @media screen and (max-width: $bp-medium) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 24px;
            margin-bottom: 0px;
          }
          :first-child {
            margin-right: 16px;
            @media screen and (max-width: $bp-medium) {
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
          .button {
            @include button;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            padding: 10px 24px;
            background: $color-purple-800;
            border-radius: 24px;
            align-self: flex-start;
            white-space: nowrap;
            margin-left: 0px;
            margin-right: 12px;
            cursor: pointer;

            .button-text {
              margin-right: 13px;
            }
            &:hover {
              background-color: $color-purple-900;
              transition: all 0.3s;
            }
          }
        }
        .button-width {
          width: 156px;
          place-content: center;
        }
      }
    }
  }
}

.carousel {
  .control-dots {
    margin-bottom: -5px;
  }
  .dot {
    box-shadow: none !important;
    background: #d7dfe7 !important;
    opacity: 1 !important;

    &.selected {
      background: #6b57b8 !important;
    }
  }
  .carousel-status {
    display: none !important;
  }
}

.carousel-root {
  .slide {
    padding: 0 15px !important;
  }
}
