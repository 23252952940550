.footer-section {
  background-color: #071b3c;

  .ArrowButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6b57b8;
    border-radius: 50%;
    min-height: 30px;
    min-width: 30px;
  }

  .footer-container {
    max-width: $bp-largest;
    margin: 0 auto;
    padding: 32px 104px 54px;
    @media only screen and (max-width: $bp-large) {
      padding: 42px 32px 35px;
    }
    @media only screen and (max-width: $bp-medium) {
      padding: 24px;
    }

    @media only screen and (max-width: $bp-small) {
      padding: 24px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: $bp-medium) {
        align-items: center;
      }
      @media only screen and (max-width: $bp-medium) {
        align-items: flex-start;
        flex-direction: column;
      }
    }
    .footer-links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      :not(:last-child) {
        margin-right: 3rem;
      }
    }
    .logo-container {
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: $bp-medium) {
        margin-bottom: 32px;
      }
      .logo {
        max-width: 118px;
        margin-bottom: 16px;

        @media only screen and (max-width: $bp-medium) {
          margin-bottom: 16px;
        }

        img {
          width: 100%;
        }
      }
      .text {
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #fff;
        max-width: 250px;
        @media only screen and (max-width: $bp-small) {
          max-width: 250px;
        }
        @media only screen and (max-width: $bp-small) {
          max-width: 250px;
          margin-bottom: 20px;
        }
      }
    }

    .footer-navigation {
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: $bp-small) {
        flex-direction: column;
      }

      li:not(:last-child) {
        margin-bottom: 16px;

        @media only screen and (max-width: $bp-small) {
          margin-bottom: 8px;
        }
      }

      li {
        list-style: none;

        a {
          outline: none;
          text-decoration: none;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #fff;
          cursor: pointer;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  label {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: block;
    cursor: pointer;
    padding-bottom: 8px;
  }
  input {
    width: 100%;

    border-radius: 48px;
    padding: 10px 0;
    height: 36px;
  }

  input#email::placeholder {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey-500;
    @media only screen and (max-width: $bp-small) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  input:focus {
    outline: none;
    cursor: pointer;
  }

  input.error {
    border: 2px solid $color-red;
  }
  input.normal {
    border: 1px solid $color-grey-500;
    margin-right: 8px;
  }

  input[type="email"] {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $color-grey-500;
    padding-left: 16px;
  }

  input:focus {
    border: 2px solid #6b57b8;
  }

  input#submit {
    border: none;
    background-color: $color-white-primary;
    // background: $color-primar-purple;
    color: $color-white-primary;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 26px;
    @media only screen and (max-width: $bp-small) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
    &:hover {
      background-color: $purple-900;
    }
  }
  .mc__form-container {
    width: 30%;
    @media only screen and (max-width: $bp-medium) {
      margin-top: 24px;
      width: 50%;
    }
    @media only screen and (max-width: $bp-small) {
      margin-top: 24px;
      width: 100%;
    }
  }
  svg:hover {
    fill: #5c2f97;
    transition: all 0.3s;
  }
}
