// @import "../__variables";

.partners-container {
  max-width: $bp-largest;
  margin: 0 auto;
  padding: 0px 104px 64px 104px;

  @media only screen and (max-width: $bp-large) {
    padding: 0px 32px 64px 32px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 0px 33px 24px 33px;
  }
  @media only screen and (max-width: $bp-small) {
    padding: 0px 24px 24px;
    grid-template-columns: 1fr;
  }

  .partners-header {
    margin-bottom: 64px;
  }
  &__heading {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: $color-grey-800;
  }

  &__text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $color-grey-800;
  }

  .partners-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 16px;
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    @media only screen and (max-width: $bp-small) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .partners-work {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08),
        0px 8px 20px rgba(0, 0, 0, 0.12);
    }
    .partners-card {
      text-align: center;

      background: #f6f7f8;
      border: 1px solid #e8eaee;
      box-sizing: border-box;

      border-radius: 8px;
      @media only screen and (max-width: 800px) {
        padding: 24px;
      }

      &__heading {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: $color-grey-800;
        padding-top: 86px;
        @media only screen and (max-width: 800px) {
          padding-top: 43px;
        }
      }
      &__text {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: $color-grey-800;
        padding-bottom: 8px;
      }
      &__button {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 86px;
      }
      &__link {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        text-decoration: underline;
        color: $color-purple-500;
      }

      &__info {
        padding: 24px;
      }
      &__image {
        height: 200px;
        margin-bottom: 16px;
        @media only screen and (max-width: $bp-small) {
          width: 200px;
          margin: 0 auto;
        }

        img {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          @media only screen and (max-width: $bp-small) {
            width: 100%;
          }
        }
      }
    }
  }
  .partners-card__contact {
    outline: none;
    border: none;
    border-radius: 24px;
    background-color: $color-purple-800;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 24px;
    margin-top: 24px;
    cursor: pointer;
    @media only screen and (max-width: 800px) {
      margin-bottom: 43px;
    }
    @media only screen and (min-width: 1289px) {
      margin-bottom: 86px;
    }

    &:hover {
      background-color: $color-purple-900;
      transition: all 0.3s;
    }
  }
  .get-in-touch-link {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
}
