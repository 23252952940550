.plan-section {
  background: linear-gradient(320deg, #153262, #071b3c);

  .plan-container {
    max-width: $bp-largest;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 64px 104px 0px 104px;

    @media only screen and (max-width: $bp-large) {
      padding: 33px 32px 24px 32px;
    }

    @media only screen and (max-width: $bp-medium) {
      padding: 33px 33px 24px 33px;
    }
    @media only screen and (max-width: $bp-small) {
      padding: 33px 24px 24px;
      grid-template-columns: 1fr;
    }

    .plan {
      padding-right: 144px;
      padding-bottom: 64px;
      @media only screen and (max-width: $bp-large) {
        padding-right: 54px;
      }
      @media only screen and (max-width: $bp-medium) {
        padding-right: 54px;
        padding-bottom: 0;
      }
      @media only screen and (max-width: $bp-medium) {
        padding-right: 0;
      }
      &-heading {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 38px;
        color: $color-white-primary;
        margin-bottom: 17px;
        @media only screen and (max-width: $bp-medium) {
          font-size: 20px;
          line-height: 27px;
          margin-bottom: 8px;
        }
        &__pro {
          background-color: $blue-500;
          padding: 0 10px;
          border-radius: 8px;
        }
      }
      &-text {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: $color-white-primary;
        margin-bottom: 52px;
        @media only screen and (max-width: $bp-medium) {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 24px;
        }
      }
      &-token {
        display: inline-block !important;
        background: $color-grey-800;
        border-radius: 45px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 4px 12px;
        color: $color-white-primary;
        margin-bottom: 53px;
        @media only screen and (max-width: $bp-medium) {
          margin-bottom: 24px;
        }
      }
      &-buttons {
        display: flex;
        @media only screen and (max-width: $bp-medium) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      &-button {
        @include button;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding: 0px 16px;
        height: 44px;
        border-radius: 45px;
        @media only screen and (max-width: $bp-medium) {
          font-size: 14px;
          line-height: 20px;
        }

        @media only screen and (max-width: $bp-small) {
          font-size: 12px;
          line-height: 16px;
          padding: 4px 12px;
        }

        &:hover {
          background-color: $blue-900;
          transition: all 0.3s;
        }
      }

      .planspage-button {
        background: #06b5dd;
        margin-right: 16px;
        @media only screen and (max-width: $bp-medium) {
          margin-right: 0;
          margin-bottom: 12px;
          width: 100%;
          justify-content: center;
        }
        &:hover {
          background-color: $blue-900;
          transition: all 0.3s;
        }
      }

      .visiontokens-button {
        background-color: $color-grey-900;
        color: white;
        @media only screen and (max-width: $bp-medium) {
          width: 100%;
          justify-content: center;
        }
        &:hover {
          background-color: $color-grey-1000;
          transition: all 0.3s;
        }
        a:hover {
          color: $color-purple-500 !important;
          transition: all 0.3s;
        }
      }
    }
    &__wallet {
      grid-column: 2;
      display: grid;
      justify-content: end;
      align-content: end;
      margin-bottom: 30px;
      @media only screen and (max-width: $bp-medium) {
        align-items: start;
        justify-content: start;
        align-content: start;
      }
      @media only screen and (max-width: 800px) {
        display: none;
      }
    }
  }
}
