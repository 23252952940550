.partnerinfo-container {
  max-width: $bp-largest;
  margin: 0 auto;
  padding: 0px 104px 107px 104px;
  display: grid;
  grid-template-columns: 38rem 1fr;
  @media only screen and (max-width: $bp-large) {
    padding: 52px 32px 32px 32px;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 52px 33px 33px 33px;
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: $bp-small) {
    padding: 52px 24px;
    grid-template-columns: 1fr;
  }

  &__info {
    // margin-right: 278px;
    grid-column: 2;
    @media only screen and (max-width: $bp-largest) {
      margin-right: px;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-column: 1;
    }

    &-heading {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 38px;
      color: $color-grey-800;
      margin-bottom: 16px;
      @media only screen and (max-width: $bp-medium) {
        font-size: 20px;
        line-height: 28px;
      }
    }
    &-text {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $color-grey-800;
      @media only screen and (max-width: $bp-medium) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    a {
      text-decoration: none;
    }
    &-button {
      background: $color-purple-800;
      border-radius: 45px;
      border: none;
      padding: 10px 24px;
      margin-top: 24px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $color-white-primary;
      cursor: pointer;
      @media only screen and (max-width: $bp-medium) {
        width: 100%;
        margin-top: 16px;
        font-size: 14px;
        line-height: 20px;
      }

      &:hover {
        background-color: $color-purple-900;
        transition: all 0.3s;
      }
    }
  }
  &__image {
    grid-column: 1;
    justify-self: center;
    @media only screen and (max-width: $bp-medium) {
      // display: none;
      grid-column: 1;
      margin-bottom: 24px;
    }
  }
}
