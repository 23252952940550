.dropbtn {
  background-color: transparent;
  position: relative;
  text-decoration: none;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $color-grey-800;
  border: none;
  cursor: pointer;
  margin-left: 0px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 36px;
  @media only screen and (max-width: 1136px) {
    margin-right: 24px;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  border: 1px solid #8d98aa;
  box-shadow: 0px 0px 3px rgba(27, 49, 85, 0.16),
    0px 4px 8px rgba(27, 49, 85, 0.24);
  border-radius: 8px;
  z-index: 1;
}

.dropdown-content a {
  color: #1b3155;
  padding: 12px 16px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: block;
  text-decoration: none;
  border-radius: 8px;
}

.dropdown-content a:hover {
  background-color: #f6f7f8;
}

.dropdown:hover .dropdown-content {
  display: block;
}
